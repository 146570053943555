.subscribe-button {
  padding: 10px 20px;
  background-color: #015ed2;
  color: white;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.subscribe-button:hover {
  background-color: #015ed2;
}

.subscribe-input {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  margin-right: 10px; /* Add some space between the input and the button */
}
