.signup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 400px;
  padding: 20px;

  .signup-input {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 1em;
  }

  .signup-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
  }

  .signup-button:hover {
    background-color: #0056b3;
  }
}
