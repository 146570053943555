.navbar {
  background-color: rgba(255, 255, 255, 0.867);

  .navbar-brand {
    color: #003249;
    font-weight: 500;
  }

  .navbar-brand {
    color: #003249;
  }

  .navbar-nav {
    color: #003249;
  }

  .coming-soon-container {
    color: red;
  }

  /* 
   The react-bootstrap library uses Bootstrap's CSS under the hood.
   In Bootstrap, the navbar collapses into a dropdown menu when the viewport width is less than 992px by default.
   This corresponds to the lg (large) breakpoint in Bootstrap. 
  */
  @media (min-width: 992px) {
    .nav-push-right {
      margin-left: 50%;
    }
  }
}
